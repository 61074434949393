import * as React from 'react'

import { i18n } from '@thg-commerce/enterprise-core'
import {
  CustomDropdown,
  IconPosition,
  OptionsType,
} from '@thg-commerce/gravity-elements/CustomDropdown/CustomDropdown'

interface SubscriptionFrequencyDropDownProps {
  title: string
  updateSubscriptionCallback?: (contractId: string) => void
  onChangeValue?: (changeValue: string) => void
  contractValue?: number | string
  availableFrequencies?: OptionsType[]
  useDefaultDropdownWidth?: boolean
  width?: string
  maxHeight?: string
}

export const SubscriptionFrequencyDropDown = (
  props: SubscriptionFrequencyDropDownProps,
) => {
  const i18nText = {
    frequencyDropdownAriaLabel: i18n('basket.subscriptiondropdown.label.text'),
    noAvailableFrequencyText: i18n(
      'product.subscriptions.nofrequency.available.text',
    ),
  }

  const dropdownPlaceholder =
    props.availableFrequencies?.find(
      (value) => value.key === props.contractValue,
    )?.displayText ||
    props.availableFrequencies?.[0]?.displayText ||
    i18nText.noAvailableFrequencyText

  return (
    <CustomDropdown
      label={props.title}
      useDefaultDropdownWidth={props.useDefaultDropdownWidth}
      width={props.width}
      placeholder={dropdownPlaceholder}
      removeLabelMargin={true}
      selected={props.contractValue}
      labelHidden={false}
      disabled={!props.availableFrequencies?.length}
      aria-label={i18nText.frequencyDropdownAriaLabel}
      options={
        (props.availableFrequencies?.length && props.availableFrequencies) || []
      }
      onChange={(event) => {
        if (!event.value) {
          return
        }
        props.onChangeValue && props.onChangeValue(event.key.toString())

        props.updateSubscriptionCallback &&
          props.updateSubscriptionCallback(event.key.toString())
      }}
      selectedIconPosition={IconPosition.RIGHT}
      maxDropdownHeight={props.maxHeight || '470px'}
    />
  )
}
